<template>
	<div style="padding-top: 581px;">
		<div class="banner" style="height: 581px;">

			<div class="bd" style="height: 581px;position: relative;">
				<div class="swiper-container swiper5">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="item in imgarr" :key="item.id">
							<img :src="item.url" style="width: 100%;height:100%;">
							</div>

					</div>
					<div class="swiper-pagination"></div>
					<div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
					<div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
				</div>
			</div>
			<div>

			</div>

		</div>
		<div class="Wrapper index" style="">
			<div class="index_1" style="width: 80%;margin: 0 auto;padding: 85px 0;padding-bottom: 0;">
				<div class="content-top">
					<div class="content-left">
						<h3>{{newscatagary[0]?.name}}</h3>
						<h6>{{newscatagary[0]?.enName}}</h6>
						<h5 style="margin-top:15px; color: #8e8e95;">{{newscatagary[0]?.describe}}</h5>
						<div style="width: 50%;"></div>

					</div>
					<div class="content-right">
						<div class="index-top" v-for="(item, index) in indexNew" :key="index"  @click="changeto(item?.id)" style="cursor: pointer;">
							<h3 style="font-size: 24px;
								color: #3c3c48;
								color: rgba(60, 60, 72, .8);
								line-height: 30px;">{{item?.subTitle||'' }}
							</h3>

							<div style="    color: rgba(115, 115, 124, .6);    font-size: 13px;
								color: #95959d;
								line-height: 24px;
								-webkit-line-clamp: 3;  
								display: -webkit-box;
								overflow: hidden;
								-webkit-box-orient: vertical;
								text-align: justify;" v-html=" item?.subContent||''">
							</div>
						</div>
						<!-- <div style="overflow: hidden;">
						</div> -->
						<div @click="changeto(false)" style="cursor: pointer;">
							<div class="dot_box" style="    
									font-size: 0;
									margin-top: 20px;
									top: -2px;
									display: inline-block;
									text-align: center;
									position: relative;"
							>
								<div class="i"></div>
								<div class="i"></div>
								<div class="i"></div>
							</div>
							<div class="text">更多新闻</div>
						</div>
					</div>



				</div>
			</div>
			<div class="index_1" style="width: 80%;margin: 0 auto;padding: 85px 0;padding-top: 45px;">
				<div class="content-top">
					<div class="content-left" style="    padding-right: 36px;    position: relative;">
						<h3>{{newscatagary[1]?.name}}</h3>
						<h6>{{newscatagary[1]?.enName}}</h6>
						<h3 style="margin-top:15px;font-size: 14.5px; color: #293d48;">
							{{ indexinfo[textindex]?.subTitle }}</h3>
						<h5
							style="    color: #8e8e95;margin-top: 18px;font-size: 100%;font-weight: normal;line-height: 22px;">
							{{ indexinfo[textindex]?.subContent }}
						</h5>
						<div style="width: 50%;"></div>
						<div class="swiper-button-prev" @click="back"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
						<div class="swiper-button-next" @click="next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
					</div>
					<div class="content-right">

						<div class="swiper-container swiper6">
							<div class="swiper-wrapper">
								<div class="swiper-slide yanshi" v-for="item in indexinfo" :key="item.id"><img
										v-lazy="item.pic" style="width: 100%;height:320px;cursor: pointer;"
										@click="routerTo(item.id)"></div>

							</div>
							<div class="swiper-pagination"></div>

						</div>


					</div>
				</div>
			</div>
			<div class="index_3" :style=" {background: `url(${bgtextitem.url})`, norepeat: 'center'}">
				<div style="position: relative;height: 100%;width: 80%;margin: 0 auto;">
					<div class="tiltle">
						<!-- 飞阳建设工程业务领域<br>
              建筑产业<br>
              现代化发展趋势 -->
						<h3 class="init" v-html="bgtextitem.contentUp">


						</h3>
						<div class="init2" v-html="bgtextitem.contentDown">

						</div>
						<!-- <p style="width: 500px;">
          飞阳包含房屋建筑市政公路、工业建筑，金属门窗、”钢结构广等业务领域 致力于数据化、智能化创新发展，积极探索建筑产业现代化发展趋势
        </p> -->

					</div>
					<div class="list" style="position: absolute;
    left: 0;
    bottom: -42px;
    margin-right: -14px;
    z-index: 2;
    width: 80vw;">
						<div class="swiper2" style="max-width: 80vw;overflow: hidden;height: 100%;">
							<div class="swiper-wrapper swiper9">

								<div class="swiper-slide" style="cursor: pointer;height: 316px;"
									v-for="item in smallPicturearr" :key="item.id" v-if="item.state=='true'"
									@click="projecto(item.id)">
									<img v-lazy="item.picture"
										style=" width: 100%;height:100%;margin: auto;border-radius: 5%;">
								</div>

							</div>
							<div class="swiper-pagination2" style="text-align: center;"></div>

						</div>
						<!-- <div><img src=""></div>
        <div></div>
        <div></div>
        <div></div> -->
					</div>
				</div>
			</div>
			<div class="index_4">
				<div style="padding: 120px 0 42px;
    position: relative;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;">
					<div class="content2-left" style="width:100%">
						<div>
							<h3 style="    line-height: 60px;
    font-weight: lighter;    font-size: 48px">{{ componydata[0]?.title }}</h3>
						</div>
						<div class="textbox">

							<!-- <h3></h3> -->
							<h5 style="line-height: 20px;">
								{{componydata[0]?.content}}<span
									style="color:RGB(48, 113, 183);float: right;font-weight: bold;cursor: pointer;"
									@click="switchto">MORE...</span>
							</h5>
							<!-- <h5></h5> -->
						</div>
					</div>
				</div>
				<div>

				</div>
			</div>
			<div style="max-width: 80vw;overflow: hidden;height: 100%;margin: auto;">
				<div class="swiper3">
					<div class="swiper-wrapper swiper9">
						<div class="swiper-slide" v-for="item in achievementarr" :key="item.id"
							v-if="item.state=='true'" @click="honorto(item.id)"
							style="background-color: white;border-radius: 10px;padding: 20px;box-sizing: border-box;cursor: pointer;">
							<img v-lazy="item.picture" style=" height:239px ;margin: auto;display: block;">
						</div>
					</div>
					<div class="swiper-pagination1" style="text-align: center;"></div>

				</div>
			</div>
		</div>

	</div>

</template>

<script>
	// @ is an alias to /src
	// import Swiper  from 'swiper';


	import Swiper from 'swiper'
	import 'swiper/css/swiper.min.css'
	import {
		achievementPic,
		NewCategary,
		hyDtai,
		swiperimg,
		backgroundText,
		smallPicture,
		honor,
		componyhonor,
		indexNews,
		indexInformation
	} from '@/api'
	export default {
		data() {
			return {
				index: 0,
				hydata: [],
				imgarr: ['', '', ''],
				achievementarr: [],
				newsitem: {},
				bgtextitem: {},
				smallPicturearr: [],
				swipe: null,
				textindex: 0,
				componydata: [],
				newscatagary: [],
				indexNew: [],
				indexinfo: []
			}

		},
		mounted() {

			//大图轮播图
			swiperimg().then((res) => {
				this.imgarr = res.data
				this.$nextTick(() => {
					new Swiper('.swiper5', {
						loop: true,
						observer: true,
						observeParents: true,
						autoplay: {
							delay: 4100,
							disableOnInteraction: false,
						},

						pagination: {
							el: '.swiper-pagination',

						},
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
					})
				})

			})

			//行业动态
			hyDtai().then((res) => {
				this.hydata = res.data
				// this.hydata.src = res.data[0].picture
				// this.hydata.content = res.data[0].content
				// this.hydata.url=res.data[0].url
				// this.hydata.name=res.data[0].name
				this.$nextTick(() => {
					this.swipe = new Swiper('.swiper7', {
						loop: true,
						allowTouchMove: false,
						// autoplay: {
						//   delay: 4100,
						//   disableOnInteraction: false,
						// },
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},

					})
				})
			})
			//背景图文轮播图
			smallPicture().then((res) => {


				let newarr = res.data.filter((item) => {
					return item.state == 'true'
				})

				this.smallPicturearr = newarr
				this.$nextTick(() => {
					new Swiper('.swiper2', {
						slidesPerView: 3,
						slidesPerGroup: 1,
						spaceBetween: 20,
						pagination: {
							el: '.swiper-pagination2'
						}
					})
				})
			})
			//荣誉展示
			honor({
				'year': 'all'
			}).then((res) => {
				this.achievementarr = res.data
				this.$nextTick(() => {
					new Swiper('.swiper3', {
						slidesPerView: 3,
						slidesPerGroup: 1,
						spaceBetween: 20,
						pagination: {
							el: '.swiper-pagination1'
						}
					})
				})
			})
			//新闻展示
			// getnews({ 'page': 0 }).then((res) => {
			//   res.data.content[0].content= res.data.content[0].content.replace(/<[^>]+>/g, '').trim();
			//   this.newsitem = res.data.content[0]

			//   console.log(this.newsitem)
			// })
			//图文展示
			backgroundText().then((res) => {
					this.bgtextitem = res.data[0]

				}),
				componyhonor().then((res) => {
					this.componydata = res.data
				})
			NewCategary().then((res) => {
				this.newscatagary = res.data

				indexInformation({
					articleType: res.data[1]?.name
				}).then((res) => {
					this.indexinfo = res.data
					this.$nextTick(() => {
						new Swiper('.swiper6', {
							loop: true,
							observer: true,
							observeParents: true,
							pagination: {
								el: '.swiper-pagination',

							},
							navigation: {
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							},
							pagination: {
								el: '.swiper-pagination2'
							}
						})
					})
				})
				indexNews({
					articleType: res.data[0]?.name
				}).then((res) => {
					console.log(1111)
					this.indexNew = res.data
				})
			})


			// 创建交叉观察器实例
			// this.observer = new IntersectionObserver(entries => {
			//   entries.forEach(entry => {
			//     if (entry.isIntersecting) {
			//       entry.target.classList.add('visible');
			//     } else {
			//       entry.target.classList.remove('visible');
			//     }
			//   });
			// });

			// 观察每个项目元素
			// this.items.forEach((item, index) => {
			//   const target = this.$refs['box' + index][0]; // 获取对应的元素
			//   this.observer.observe(target); // 开始观察元素
			// });



			// 初始化Swiper

		},
		methods: {



			next() {

				// if(this.textindex<this.hydata.length-1){
				//   this.textindex=this.textindex+1
				// }else{
				//   this.textindex=0
				// }
				if (this.textindex >= this.indexinfo.length - 1) {
					this.textindex = 0
				} else {
					this.textindex = this.textindex + 1
				}
			},
			back() {

				// if(this.textindex>0){
				//   this.textindex=this.textindex-1
				// }else{
				//   this.textindex=this.hydata.length-1
				// }

				this.textindex = this.textindex - 1
				if (this.textindex < 0) {
					this.textindex = this.indexinfo.length - 1
				}

			},
			switchto() {
				this.$router.push({
					path: '/aboutus',
					query: {
						'category': 'honor'
					}
				})
			},
			changeto(id) {
				if (id) {

					this.$router.push({
						path: '/detail?id=' + id
					})
				} else {
					console.log(id)
					this.$router.push({
						path: '/news'
					})
				}

			},
			routerTo(id) {
				if (id) {

					this.$router.push({
						path: '/detail?type=1&id=' + id
					})
				} else {
					console.log(id)
					this.$router.push({
						path: '/news'
					})
				}
			},
			honorto(id) {
				this.$router.push({
					path: '/honordetail?id=' + id
				})
			},
			projecto(id) {
				this.$router.push({
					path: '/projectdetail?id=' + id
				})
			}
		},
		beforeDestroy() {
			// 在组件销毁之前停止观察器
			// if (this.observer) {
			//   this.items.forEach((item, index) => {
			//     const target = this.$refs['box' + index][0];
			//     this.observer.unobserve(target);
			//   });
			// }
		}
	}
</script>
<style scoped>
	.banner {
		width: 100%;
		margin: 0 auto !important;
		overflow: hidden;
		min-width: 1260px;
		height: 700px;
		position: fixed !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: black;
	}

	.banner .bd {
		width: 100%;

	}

	.Wrapper {
		position: relative;
		background-color: #fff;
		z-index: 100;
	}

	.content {
		width: 1280px;
		margin: 0 auto;
	}

	/* 样式可根据需求自定义 */
	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide1 {
		min-width: calc(26.66666vw - 14px);
		max-width: calc(26.66666vw - 14px);

		margin-right: 14px;

	}


	.swiper-slide2 {
		width: 100%;

	}

	.swiper-slide::after {
		content: '';
		left: 0px;
		position: absolute;
		bottom: 0px;
		width: 0%;
		height: 4px;
		background-color: #DDCAB8;
		transition: width ease-in-out 4s;
	}

	.swiper-slide-active::after {
		width: 100%;
	}

	h3 {
		font-size: 30px;
		color: RGB(48, 113, 183);
		line-height: 1;
		font-weight: lighter;
		margin-bottom: 9px;
		font-weight: 400;
	}

	h6 {
		font-family: 'Rajdhani';
		font-size: 15px;
		color: #c0a988;
		line-height: 1;
		text-transform: uppercase;
		font-weight: bold;
		margin: 0;
	}

	.content-left {
		width: 33.5%;
	}

	.content-right {
		width: 67%;
	}

	.shares h5 {
		font-family: 'Rajdhani';
		font-size: 20px;
		color: #474747;
		line-height: 1;
		font-weight: bold;
		letter-spacing: -.2px;
		position: relative;
		padding-left: 12px;
		margin-left: 14px;
		display: inline-block;
	}


	.shares h5:before {
		content: '';
		width: 2px;
		height: 30px;
		background-color: #d9d9d9;
		position: absolute;
		left: 0;
		top: 8px;
	}

	.index-top {
		position: relative;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 2px solid #c9c9c9;
		border-bottom: 2px solid rgba(201, 201, 201, .5);

	}

	.content-top {
		display: flex;
	}

	.content2-left {
		padding-top: 14px;
		width: 33%;
	}

	.content2-left .textbox h3 {
		font-size: 14.5px;
		color: #293d48
	}

	.content2-left .textbox h5 {
		color: #8e8e95;
		margin-top: 18px;
		font-weight: normal;
	}

	.content2-right {
		width: 66.5%;
		float: right;
	}

	.index_3 {
		padding-top: 140px;
		width: 100%;
		height: 604px;

		background-attachment: fixed;
		background-size: cover;
	}

	.tiltle h3 {
		font-size: 48px;
		color: RGB(48, 113, 183);
		line-height: 60px;
		margin-top: 48px;
		margin-bottom: 14px;
		font-weight: lighter;
	}

	.tiltle p {
		font-size: 16px;
		line-height: 24px;
		color: rgba(255, 255, 255, .5);
		font-weight: bold;
	}

	.swiper9 .swiper-slide::after {
		all: initial
	}

	.swiper-slide9 img {
		width: 100%;

	}

	.dot_box .i {
		display: inline-block;
		width: 6px;
		height: 6px;

		background-color: RGB(48, 113, 183);
		border-radius: 50%;
		margin: 0 2px;
	}

	.text {
		font-size: 14.5px;
		color: RGB(48, 113, 183);
		display: inline-block;
	}

	.init p {
		margin: 0 !important;
	}

	.init2 {
		font-size: 16px;
		line-height: 24px;
		color: rgba(255, 255, 255, .5);
		font-weight: bold;
	}

	.flow {}

	.a::after {
		background-color: unset;
	}

	.yanshi::after {
		width: 0px !important;
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		left: 10px;
		right: auto;
		top: auto;
		bottom: 0 !important;
	}

	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 36px;
		left: auto;
		top: auto;
		bottom: 0 !important;
	}
</style>