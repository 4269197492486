<template>
  <div>
    <div id="header" :class="{  'topheader': isScrolled }">
        
        <div :class="{ 'header': true, 'fixed-width': isScrolled }" >
          <img style="margin: 24px 0 0 42px;width: 213px;object-fit: contain;" src="@/assets/images/logo.png" >
          <el-menu style="margin-right: 15px;float: right;height: 100%;display: flex;align-items: center;background-color:transparent ;border: none;"  :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="switchto" >
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">走进飞阳</el-menu-item>
            <el-menu-item index="3">新闻内容</el-menu-item>
            <el-menu-item index="4">工程展示</el-menu-item>
            <el-menu-item index="5">人才招聘</el-menu-item>
            <el-menu-item index="6">联系我们</el-menu-item>
            </el-menu>       
        </div>
            </div>
    <router-view/>
    <div class="footer">
      <div class="content" style="display: flex;justify-content: space-between;text-decoration: none;">
    <div class="text" >
      <!-- <h3>020-89898233</h3> -->
      <p style="margin-top: 0;">地址：福建省莆田市荔城区荔园中路西侧50米飞阳建设工程有限公司</p>
      <p>飞阳建设集团 版权所有</p>
   

          <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备12004115号-1</a>
    </div>
    <div class="foot_section" style="display: inline-block;">
      <div class="links_list" style="display: inline-block; vertical-align: top;">
        <a href="">法律声明</a>
        <a href="">隐私政策</a>
        <a href="">网站地图</a>
        <a href="" target="_blank">设计支持</a>
      </div>
      <div class="socials_wrap" style="display: inline-block;position: relative;">
       <img class="weixin" src="@/assets/images/微信.png" style="margin-left: 15px;">
       <div class="weChat_img"><img src="@/assets/微信二维码.png"></div>
       <img src="@/assets/images/移动端.png" style="margin-left: 10px;">
      </div>
    </div>
  </div>
    </div>
  </div>
</template>
<script>
import request from '@/request/index.js'
export default{
  data(){
    return{
      isScrolled:false,
      activeIndex:'1',
      routerlist:{
        '1':'/',
        '2':'/aboutus',
        '3':'/news',
        '4':'/project',
        '5':'/recruit',
        '6':'/contactus'
      }
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
    console.log(request,111)
   for(var i in this.routerlist){
      if(this.$route.path==this.routerlist[i]){
        this.activeIndex=i
      }
     
   }
  },
  beforeDestroy() {
    // 组件销毁前移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    switchto(index){
     if(location.pathname==this.routerlist[index]){
      return
     }
this.$router.push({
  path:this.routerlist[index]
})
    },
    handleScroll() {
      // 获取滚动条位置
      const scrollPosition = window.scrollY;

      // 根据滚动条位置判断是否添加类名
      if (scrollPosition > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.footer .text p, .footer .text a {
    font-weight: bold;
    color: #3f3e55;
    
    transition: all .4s;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    font-size: 14px;
    color: #95959d;
    line-height: 24px;
    text-align: justify;
    text-decoration: none;
}
.foot_section .links_list a:not(:first-child)::before {
    content: '\2022'; /* 使用 Unicode 编码表示小黑点 */
    color: #656565;/* 设置黑色 */
    display: inline-block; /* 将伪元素转换为块级元素，以便设置宽度和高度 */
    width: 3px;
    height: 3px;
    margin-right: 15px; /* 设置与链接之间的间距 */
}
.foot_section .links_list a{
  font-size: 14px;
  text-decoration: none;
  position: relative;
    font-weight: bold;
    color: #656565;
    line-height: 33px;
    padding-left: 10px;
    margin-left: 3px;
    opacity: .6;
}

.footer {
    width: 100%;
    padding: 28px 0 37px;
    background-color: #f7f6f4;
    position: relative;
    z-index: 102;
}
.footer .content {
    position: relative;
    height: 100%;
}
.content {
    width: 1280px;
    margin: 0 auto;
}
#header{
  width: 100%;
    min-width: 1280px;
    max-width: 100%;
    margin: auto;
    position: fixed;
    top: 43px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    transition: all .4s;
    -webkit-transition: all .4s;

}
.header {
  box-sizing: border-box;
    width: 90%;
    // min-width: 1280px;
    height: 82px;
    margin: auto;
    position: relative;
    background-size: cover;
    z-index: 100;
    background-color: white;
   border-radius: 10px;
   font-size: 14px;
   -webkit-transition: all .4s;
   font-weight: bold;
}

.fixed-width{
  border-radius: 0;
  width: 100%;
}
.topheader{
   top:0 !important;
   max-width: 100% !important;
}

.weChat_img {
    position: absolute;
    top: -115px;
    left: -26%;
    width: 107px;
    height: 107px;
  
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    z-index: 99;
    opacity: 0;
    filter: Alpha(opacity = 0);
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    will-change: opacity;
    -webkit-transition: transform .4s, opacity .4s, -webkit-transform .4s;
    transition: transform .4s, opacity .4s, -webkit-transform .4s;
    padding: 5px;
}

.socials_wrap .weChat_img img {
    width: 100%;
    height: 100%;
}
.socials_wrap img:hover + .weChat_img {
    opacity: 1;
    filter: Alpha(opacity = 100);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
}
</style>
