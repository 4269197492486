<template>
	<div>
		<div class="page_ban">
			<div class="img compbg"></div>
			<div class="content">
				<div class="page_nav">
					<div class="name">
						<a style="padding: 0;" href="/project">工程资讯</a> <a href="javascript:;">工程详情</a>
					</div>
					<div class="list" style="bottom:-15px">
						<el-tabs v-model="activeName">
							<el-tab-pane label="工程详情" name="first"></el-tab-pane>

						</el-tabs>

					</div>
				</div>
			</div>
		</div>
		<div class="Wrapper news_d">
			<div style="display: flex;width: 95%;justify-content: space-between;">
				<div style="width: 30%;background-color:rgb(228,235,241);">
					<div style="width: 100%;padding-left: 30%;box-sizing: border-box;">
						<div class="z_xq_cont_top_lt">工程简介</div>
						<div class="z_xq_cont_top_ls"></div>
						<!-- <div class="z_xq_cont_top_lc"><img src="https://www.fygroup.cn//skin/images/xqjt.png"></div> -->
						<div class="z_xq_cont_top_lx">
							<div class="jzmssj">{{area}} <span class="dwi">㎡</span></div>
							<div class="jzmsms">工程建筑面积</div>
						</div>
						<div class="z_xq_cont_top_lx">
							<div class="jzmssj">{{ height }} <span class="dwi">M</span></div>
							<div class="jzmsms">建筑高度</div>
						</div>
					</div>
				</div>
				<div style="width: 70%;">
					<div class="content ql-editor" style="text-align: center;">
						<div class="name">{{ name }}</div>
						<div class="top-box">
							<!-- <div class="date">{{time}}</div>  -->
						</div>
						<div class="divider"></div>
						<div class="cont" v-html="content"></div>
					</div>

					<div class="con">
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		projectdetail
	} from '@/api';
	export default {
		data() {
			return {
				name: '',
				content: '',
				time: '',
				area: '',
				height: ''
			}
		},
		methods: {

		},
		mounted() {
			projectdetail(this.$route.query).then((res) => {
				this.content = res.data.particulars
				this.name = res.data.name
				this.time = res.data.completeTime
				this.area = res.data.area
				this.height = res.data.height
			})
		},
	}
</script>
<style scoped>
	.divider {
		width: 100%;
		height: 3px;
		background-color: #0059a7;
		position: static;
		margin-bottom: 28px;
	}

	.name {
		font-size: 30px;
		color: #0007a9;
		color: rgba(0, 7, 169, .8);
		line-height: 36px;
	}

	.news_d {
		padding: 60px 0 0px;
		overflow: hidden;
	}

	.Wrapper {
		position: relative;
		background-color: #fff;
		z-index: 100;
	}

	/* .news_d .name {
    font-size: 30px;
    color: #0007a9;
    color: rgba(0, 7, 169, .8);
    line-height: 36px;
} */

	::v-deep .el-tabs__item {

		color: rgba(255, 255, 255, 0.8);
		border: none;
	}

	::v-deep .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	.news_d .con p {
		font-size: 14.5px;
		color: #8e8e95;
		line-height: 26px;
		margin-bottom: 26px;
		text-align: justify;
	}


	.compbg {
		background-image: url('../assets/images/banner.png');
	}

	.page_ban {
		height: 404px;
		position: relative;
		text-align: center;
		z-index: 101;
	}

	.page_ban .img {
		width: 100%;
		height: 100%;

		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	.top-box {
		margin: 16px 0 30px;
	}

	.date {

		font-size: 26px;
		color: #dfd4c3;
		line-height: 1;

		bottom: 20px;
		padding: 0 30px;
		width: 100%;
	}

	.page_ban .content {
		position: relative;
		height: 100%;
	}

	.content {
		width: 86%;
		margin: 0 auto;
	}

	.page_nav {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	div,
	li {
		box-sizing: border-box;
	}

	.page_nav .name {
		float: left;
	}

	.page_nav .list {
		position: relative;
		float: right;
		margin-right: -35px;
	}

	.page_nav a {
		float: left;
		line-height: 76px;
		position: relative;
		font-size: 14.5px;

		color: white;
		font-weight: bold;
		text-decoration: none;
	}

	.page_nav .name a {
		padding-left: 30px;
		/* background: url() no-repeat left 30px; */
	}

	.page_nav .list {
		position: relative;
		float: right;
		margin-right: -35px;
	}

	.page_nav .list a {
		color: #e7dfd2;
		color: rgba(231, 223, 210, .7);
		margin-right: 35px;
	}

	.page_nav a {
		float: left;
		line-height: 76px;
		position: relative;
		font-size: 14.5px;

		color: rgba(255, 255, 255, 0.8);
		font-weight: bold;
	}

	.z_xq_cont_top_lt {
		font-size: 25px;
		color: #000000;
		margin-top: 55px;
		font-weight: bold;
	}

	.z_xq_cont_top_ls {
		font-size: 20px;
		color: #263d8f;
		margin: 20px 0;
	}

	.z_xq_cont_top_lc {
		overflow: hidden;
	}

	.z_xq_cont_top_lc img {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		vertical-align: middle;
		border: 0;
	}

	.z_xq_cont_top_lx {
		height: 88px;
		border-left: 1px solid #CCCCCC;
		padding-left: 10px;
		margin-top: 50px;
	}

	.z_xq_cont_top_lx .jzmssj {
		font-size: 46px;
		color: #263d8f;
		font-weight: 100;
		line-height: 52px;
	}

	.z_xq_cont_top_lx .jzmssj .dwi {
		font-size: 14px;
		color: #666666;
	}

	.cont {
		display: flex;
		width: 100%;
	}

	.cont>>>img {
		width: 100%;
	}
</style>