<template>
    <div>
        <div class="page_ban">
            <div class="img compbg"></div>
            <div class="content">
                <div class="page_nav">
                    <div class="name">
                        <a style="padding: 0;" href="">人才招聘</a> <a href="javascript:;">人才招聘</a>
                    </div>
                
                </div>
            </div>
        </div>
        <div class="content">
            <div style="height: 60px;"></div>
            <div style="font-size: 26px;color:rgba(0,0,0,.6)">{{componyarr[0].titleUp}}</div>
            <div style="color:rgba(60,115,164);font-size:34px">{{componyarr[0].sketchUp}}</div>
            <div style="height: 40px;"></div>
            <div style="color: rgba(0,0,0,.6);line-height: 30px;"v-html="componyarr[0].contentUp">
                 
                
                </div>
            

                <div style="height: 30px;"></div>
            <img :src="componyarr[0].picture" style="display: block;width: 100%;object-fit: contain;">
            <div style="height: 30px;"></div>
    
        <div style="height: 60px;"></div>
            <div style="font-size: 26px;color:rgba(0,0,0,.6)">{{componyarr[0].titleIn}}</div>
            <div style="color:rgba(60,115,164);font-size:34px;line-height: normal;">{{componyarr[0].sketchIn}}
</div>
            <div style="height: 40px;"></div>
            <div style="color: rgba(0,0,0,.6);line-height: 18px;" class="init" v-html="componyarr[0].contentIn">
                    
                </div>
                <div style="height: 60px;"></div>
            <div style="font-size: 26px;color:rgba(0,0,0,.6)">{{componyarr[0].titleDown}}</div>
            <div style="color:rgba(60,115,164);font-size:34px;line-height: normal;">{{componyarr[0].sketchDown}}
</div>
            <div style="height: 40px;"></div>
            <div style="color: rgba(0,0,0,.6);line-height: 18px;" class="init" v-html="componyarr[0].contentDown">
                   

                 
                </div>
                
            <div style="height: 100px;"></div>
        </div>
    </div>
</template>
<script>
import { getcruit } from '@/api';
export default{
    data(){
        return{
            componyarr:[]
        }
    },
    mounted(){
        getcruit().then((res)=>{
            this.componyarr=res.data  
        })
    }
}
</script>
<style scoped>
.content{
    width: 80%;
    margin:auto
}
.compbg {
    background-image: url('../assets/images/banner.png');
}

.page_ban {
    height: 404px;
    position: relative;
    text-align: center;
    z-index: 101;
}

.page_ban .img {
    width: 100%;
    height: 100%;

    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.page_ban .content {
    position: relative;
    height: 100%;
}
.content {
    width: 80%;
    margin: 0 auto;
}

.page_nav {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
div,
li {
    box-sizing: border-box;
}

.page_nav .name {
    float: left;
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: white;
    font-weight: bold;
    text-decoration: none;
}

.page_nav .name a {
    padding-left: 30px;
    /* background: url() no-repeat left 30px; */
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav .list a {
    color: #e7dfd2;
    color: rgba(231, 223, 210, .7);
    margin-right: 35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
}

.page_nav .inner_bor {
    height: 4px;
    background: #f23c39;
    position: absolute;
    bottom: -4px;
    left: 0;
    display: block;
}

</style>