<template>
	<div>

		<div class="page_ban">
			<div class="img compbg"></div>
			<div class="content">
				<div class="page_nav">
					<div class="name">
						<a style="padding: 0;" href="/aboutus">走进飞阳</a> <a href="javascript:;">公司简介</a>
					</div>
					<div class="list" style="bottom:-15px">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="公司简介" name="first"></el-tab-pane>
							<el-tab-pane label="公司荣誉" name="second"></el-tab-pane>

							<el-tab-pane label="企业文化" name="fourth"></el-tab-pane>
						</el-tabs>

					</div>
				</div>
			</div>
		</div>
		<div v-show="activeName == 'first'">
			<div style="width: 80%;margin: auto;">
				<div style="height: 50px;"></div>
				<div style="font-size: 26px;color:rgba(0,0,0,.6)">{{componyarr[0].titleUp}}</div>
				<div style="color:rgba(60,115,164);font-size:40px">{{componyarr[0].sketchUp}}</div>
				<div style="height: 40px;"></div>
				<div style="color: rgba(0,0,0,.6);line-height: 30px;width: 57%;" v-html="componyarr[0].contentUp">


				</div>
				<div style="height: 70px;"></div>
			</div>
			<div class="about2">
				<div class="content">
					<div class="bg">
						<div class="float wow fadeInUp animated">
						</div>
						<div class="introduce">
							<h3 class="wow fadeInUp animated"
								style="animation-delay: 0.1s; visibility: visible; animation-name: fadeInUp;">
								<span>信</span><span style="margin-left:44px">责任</span><span
									style="margin-left:44px">和谐</span><span style="margin-left:44px">效益</span>
							</h3>
							<p class="wow fadeInUp animated"
								style="animation-delay: 0.5s; visibility: visible; animation-name: fadeInUp;">
								公司秉承“诚信、责任、和谐、效益”的核心价值观
							</p>
							<p class="wow fadeInUp animated"
								style="animation-delay: 0.5s; visibility: visible; animation-name: fadeInUp;">
								连年被授予国家“守合同重信用企业”、莆田市“纳税大户”、<br>"诚信企业先
								进单位”、“建筑业骨干企业”、 “综合实力十强” <br>等光荣称号。
							</p>

						</div>
					</div>
				</div>
			</div>

			<div style="width: 80%;margin: auto;">
				<div style="height: 50px;"></div>
				<div style="font-size: 26px;color:rgba(0,0,0,.6)">{{componyarr[0].titleIn}}</div>
				<div style="color:rgba(60,115,164);font-size:40px">{{componyarr[0].sketchIn}}
				</div>
				<div style="height: 40px;"></div>
				<div style="color: rgba(0,0,0,.6);line-height: 30px;width: 57%;" class="init"
					v-html="componyarr[0].contentIn">

				</div>
				<div style="height: 40px;"></div>
			</div>
			<div style="width: 80%;margin: auto;">

				<div style="font-size: 26px;color:rgba(0,0,0,.6)">{{componyarr[0].titleDown}}</div>
				<div style="color:rgba(60,115,164);font-size:40px">{{componyarr[0].sketchDown}}
				</div>
				<div style="height: 40px;"></div>
				<div style="color: rgba(0,0,0,.6);line-height: 30px;width: 57%;" class="init"
					v-html="componyarr[0].contentDown">

				</div>
				<div style="height: 70px;"></div>
			</div>
			<div class="about_4">
				<div class="content">
					<div class="introduce">
						<h3 class="wow fadeInUp animated"
							style="animation-delay: 0.2s; visibility: visible; animation-name: fadeInUp;">LEADING THE
							LOCAL INDUSTRY
						</h3>
						<h5 class="wow fadeInUp animated"
							style="animation-delay: 0.4s; visibility: visible; animation-name: fadeInUp;">本地行业之首
							出
							10余个国
						</h5>
						<p class="wow fadeInUp animated"
							style="animation-delay: 0.5s; visibility: visible; animation-name: fadeInUp;">
							自有两个具有设计、生产、安装能力的钢结构装配式基地，制造年产能超过5万吨
							位居本地行业之首,<br>产品出口美非亚等10余个国家
						</p>

						<h6 class="wow fadeInUp" style="animation-delay: 0.6s;  animation-name: none;">荣获多个优质工程的荣誉称号
						</h6>
						<p class="wow fadeInUp animated"
							style="animation-delay: 0.5s; visibility: visible; animation-name: fadeInUp;">
							获得了保利地产、建发地产、百威英博(ABInbev) 、美国皇冠制罐、佳通轮胎、南
							平铝业、福清核电、<br>湄洲湾核电、鹭燕医药等众多国内外客户的赞誉和认可。
						</p>


					</div>
					<div class="bl_map">
						<img src="@/assets/images/map.png">

						<div class="scope s3">
							<p class="no_margin">中国内地</p>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div v-show="activeName == 'second'">
			<!--时间线-->
			<div class="timeLine" style="overflow: hidden;">


				<div class="ul_box" style="padding: 50px 0px;">

					<ul class="my_timeline" ref="mytimeline" style=" display: flex;width: 80%;padding: 0;margin: auto;">
						<div class="my_timeline_prev" @click="moveLeft()">
							<i style="color: skyblue;" class="el-icon-arrow-left"></i>
						</div>
						<li class="my_timeline_item" v-for="(item, index) in timeLineList" :key="index">
							<!--圈圈节点-->
							<div class="my_timeline_node" @click="changeActive(index,item)"
								:class="{ active: index == timeIndex }"> {{ item.timestamp }}</div>
							<!--线-->

							<!--标注-->

						</li>
						<div class="my_timeline_next" @click="moveRight()">

							<i style="color: skyblue;" class="el-icon-arrow-right"></i>

						</div>
					</ul>
				</div>
			</div>
			<div class="images_cl">
				<div style="width:   auto;margin-bottom: 20px;border-radius: 10px;cursor: pointer;"
					v-for="item in honorList" :key="item.id" @click="changeto(item.id)">
					<div style="background-color: rgb(240,246,250);    border-radius: 14px 14px 0 0;">


					</div>

					<div class="img_cl" style="height: 100%;text-align: center;    border: 5px solid #F8F8F8;">


						<img :src="item.picture" alt="200603" style="scale: 0.85;height: 285px;width: 379px">
					</div>
				</div>


			</div>
			<div v-if="!honorList.length"
				style="height: 400px;display: flex;justify-content: center;align-items: center;">
				<div style="color: #999;">该年份暂无内容展示</div>
			</div>
		</div>
		<div v-show="activeName == 'fourth'" style="margin-bottom: -50px;">
			<div style="height: 50px;"></div>
			<div style="width: 80%;margin: auto;display: flex;justify-content: space-between;">
				<div style="width:   32%;margin-bottom: 20px;">
					<div style=" ">
						<img src="@/assets/images/rongyu/yuanjing.png"
							style="width: 100%;object-fit: contain;margin: auto;display: block; border-radius: 14px 14px 0 0;">
					</div>
					<div
						style="background-color: rgb(248,248,248);padding: 36px 42px 0;    border-radius: 0 0 14px 14px ;line-height: 24px;height: 230px;">
						<h3 style="color:rgb(108,108,118);margin: 0;">{{ culturelist[2].name }}</h3>
						<h5>{{culturelist[2].synopsis}}</h5>
						<p style="color: rgb(169,169,169);margin: 0;font-size: 14px;" v-html="culturelist[2].content">
						</p>
					</div>
				</div>
				<div style="width:   32%;margin-bottom: 20px;">
					<div style=" ">
						<img src="@/assets/images/rongyu/shiming.png"
							style="width: 100%;object-fit: contain;margin: auto;display: block; border-radius: 14px 14px 0 0;">
					</div>
					<div
						style="background-color: rgb(248,248,248);padding: 36px 42px 0;    border-radius: 0 0 14px 14px ;line-height: 24px;height: 230px;">
						<h3 style="color:rgb(108,108,118);margin: 0;">{{ culturelist[1].name }}</h3>
						<h5>{{culturelist[1].synopsis}}</h5>
						<p style="color: rgb(169,169,169);margin: 0;font-size: 14px;" v-html="culturelist[1].content">
						</p>
					</div>
				</div>
				<div style="width:   32%;margin-bottom: 20px;">
					<div style=" ">
						<img src="@/assets/images/rongyu/jiazhi.png"
							style="width: 100%;object-fit: contain;margin: auto;display: block; border-radius: 14px 14px 0 0;">


					</div>
					<div
						style="background-color: rgb(248,248,248);padding: 36px 42px 0;    border-radius: 0 0 14px 14px ;line-height: 24px;height: 230px;">
						<h3 style="color:rgb(108,108,118);margin: 0;">{{ culturelist[0].name }}</h3>
						<h5>{{culturelist[0].synopsis}}</h5>
						<p style="color: rgb(169,169,169);margin: 0;font-size: 14px;" v-html="culturelist[0].content">
						</p>



					</div>

				</div>

			</div>
			<div style="position: relative;top: -50px;z-index: -1;">
				<img style="width: 100%;object-fit: contain;" src="@/assets/images/rongyu/bg.png">
			</div>
		</div>
	</div>
</template>
<script>
	import {
		honor,
		culture,
		compony
	} from '@/api';
	import {
		honorList
	} from '@/api';
	export default {
		data() {
			return {
				activeName: 'first',
				timeIndex: 0,
				timeLineList: [{
					"timestamp": "全部",
					"color": "#999",
					"fontsize": 18,
					"year": "all",
					"bgcolor": "#e4e7ed",

				}, {
					"timestamp": "2024年",
					"color": "#999",
					"fontsize": 18,
					"year": "2024",
					"bgcolor": "#e4e7ed",

				}, {
					"timestamp": "2023年",
					"color": "#999",
					"fontsize": 18,
					"size": "28",
					"year": "2023",

					"bgcolor": "#e4e7ed",

				}, {
					"timestamp": "2022年",
					"color": "#999",
					"fontsize": 18,
					"size": "28",
					"year": "2022",

					"bgcolor": "#e4e7ed",

				}, {
					"timestamp": "2021年",
					"color": "#999",
					"fontsize": 18,
					"size": "28",
					"year": "2021",

					"year": "2021",

				}, {
					"timestamp": "2020年",
					"color": "#999",
					"fontsize": 18,
					"year": "2020",
					"size": "28",

				}, {
					"timestamp": "2019年",
					"color": "#999",
					"fontsize": 18,
					"size": "28",
					"year": "2019",

					"bgcolor": "#e4e7ed",

				}, {
					"timestamp": "2018年",
					"color": "#999",
					"fontsize": 18,
					"size": "28",
					"year": "2018",

					"bgcolor": "#e4e7ed",

				}, {
					"timestamp": "2017及前",
					"color": "#999",
					"fontsize": 18,
					"size": "28",
					"bgcolor": "#e4e7ed",
					"icon": "el-iconprev",
					"year": "2017",
					"info": "chengli"
				}],
				honorList: [],
				culturelist: [],
				componyarr: [],
			}
		},
		mounted() {
			// honor({'year':'2019'}).then((res)=>{
			//   console.log(res)
			// })
			compony().then((res) => {
				this.componyarr = res.data
			})
			if (this.$route.query.category) {
				this.activeName = 'second'
			}
			this.getHonner('all')
			culture().then((res) => {
				this.culturelist = res.data
			})
		},
		methods: {

			async getHonner(year) {


				const {
					data: res
				} = await honorList({
					'year': year
				});
				console.log(res)
				this.honorList = res
			},
			handleClick(tab, event) {
				console.log(tab.index);
			},
			changeActive(index, item) {
				this.timeIndex = index;
				console.log(item)
				this.getHonner(item.year)
			},
			moveLeft() {

				//  let marginLeft = parseInt(this.$refs.mytimeline.style.marginLeft);
				// let listNum = 0;
				// if(marginLeft <= 10 && (marginLeft >= -650)){
				//     this.$refs.mytimeline.style.marginLeft = marginLeft - 220 + 'px';
				if (this.timeIndex < 1) {
					return
				} else {
					this.timeIndex = this.timeIndex - 1
					console.log(this.timeIndex)
					const year = this.timeLineList[this.timeIndex].year
					console.log(year)
					this.getHonner(year)
				}

			},
			moveRight() {
				// let marginLeft = parseInt(this.$refs.mytimeline.style.marginLeft);
				// if(marginLeft < (-200)){
				//     this.$refs.mytimeline.style.marginLeft = marginLeft + 220 + 'px';
				// }
				if (this.timeIndex > this.timeLineList.length - 2) {
					return
				} else {
					this.timeIndex = this.timeIndex + 1
					const year = this.timeLineList[this.timeIndex].year
					console.log(year)
					this.getHonner(year)
				}
			},
			changeto(id) {
				this.$router.push({
					path: '/honordetail?id=' + id
				})
			}
		}
	};
</script>
<style scoped lang="scss">
	.images_cl {
		width: 80%;
		margin: auto;
		flex-wrap: wrap;
		justify-content: space-between;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(31.33%, 1fr));
	}
	@media screen and (max-width:1523px) {
		.img_cl {
			img {
				width: 100% !important;
			}
		}
	}
	
	@media screen and (max-width:1241px) {
		.images_cl {
			grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
		}
	}


	.compbg {
		background-image: url('../assets/images/banner.png');
	}

	.page_ban {
		height: 404px;
		position: relative;
		text-align: center;
		z-index: 101;
	}

	.page_ban .img {
		width: 100%;
		height: 100%;

		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	.page_ban .content {
		position: relative;
		height: 100%;
	}

	.content {
		width: 80%;
		margin: 0 auto;
	}

	.page_nav {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	div,
	li {
		box-sizing: border-box;
	}

	.page_nav .name {
		float: left;
	}

	.page_nav .list {
		position: relative;
		float: right;
		margin-right: -35px;
	}

	.page_nav a {
		float: left;
		line-height: 76px;
		position: relative;
		font-size: 14.5px;

		color: white;
		font-weight: bold;
		text-decoration: none;
	}

	.page_nav .name a {
		padding-left: 30px;
		/* background: url() no-repeat left 30px; */
	}

	.page_nav .list {
		position: relative;
		float: right;
		margin-right: -35px;
	}

	.page_nav .list a {
		color: #e7dfd2;
		color: rgba(231, 223, 210, .7);
		margin-right: 35px;
	}

	.page_nav a {
		float: left;
		line-height: 76px;
		position: relative;
		font-size: 14.5px;

		color: rgba(255, 255, 255, 0.8);
		font-weight: bold;
	}

	.page_nav .inner_bor {
		height: 4px;
		background: #f23c39;
		position: absolute;
		bottom: -4px;
		left: 0;
		display: block;
	}

	::v-deep .el-tabs__item {

		color: rgba(255, 255, 255, 0.8);
		border: none;
	}

	::v-deep .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	.bg {
		background-image: url(' ../assets/images/公司简介背景.png');
		background-size: cover;
	}

	.about2 .bg {
		position: relative;
		width: 100%;
		height: 540px;

		background-size: cover;
		padding-top: 155px;
		background-size: 100% 100%;
		border-radius: 14px;
		-webkit-border-radius: 14px;
		-moz-border-radius: 14px;
		-ms-border-radius: 14px;
		-o-border-radius: 14px;
	}

	.about2 .float {
		width: 335px;
		height: 286px;
		background: no-repeat center;
		background-size: cover;
		position: absolute;
		top: 43px;
		left: 76px;
		background-image: url('../assets/images/com-logo.png');
	}

	.about2 .introduce {
		width: 60.5%;
		float: right;
	}

	.about2 h3 {
		font-size: 36px;
		color: #fff;
		line-height: 1;
		/* letter-spacing: 44px; */
		font-weight: lighter;
		margin-bottom: 22px;
	}

	.about2 p {
		font-size: 15px;
		color: #262a3b;
		color: rgba(38, 42, 59, .7);
		line-height: 26px;
	}

	.about_4 {
		min-height: 660px;
		background: url('../assets/images/bg.png') no-repeat center;
		background-size: cover;
	}

	.about_4 .content {
		padding: 138px 0 0;
		position: relative;
		height: 100%;
	}

	.content {
		width: 80%;
		margin: 0 auto;
	}

	.about_4 h3 {
		font-family: 'Rajdhani';
		font-size: 48px;
		color: #ffffff;
		color: rgba(255, 255, 255, .5);
		line-height: 1;
	}

	.about_4 h5 {
		font-size: 30px;
		color: #00b4b7;
		line-height: 1;
		margin: 8px 0 10px;
	}

	.about_4 p {
		font-size: 15px;
		color: #ffffff;
		color: rgba(255, 255, 255, .44);
		line-height: 30px;
	}

	.about_4 h6 {
		font-size: 48px;
		color: #00b4b7;
		line-height: 1;
		font-weight: lighter;
		margin: 52px 0 12px;
	}

	.about_4 ul {
		padding-left: 18px;
		list-style: inherit;
	}

	.about_4 li {
		list-style: inherit;
		color: #ffffff;
	}

	.about_4 .bl_map {
		position: absolute;
		top: 87px;
		right: -110px;
	}

	.bl_map {
		position: relative;
		width: 951px;
	}

	.bl_map .scope.s3 {
		width: 166px;
		height: 166px;
		left: 62%;
		top: 21%;
	}

	.bl_map .scope {
		position: absolute;
		z-index: 9;
	}

	.bl_map .scope.s3:before {
		animation: h_map 2s .6s linear alternate infinite;
		-webkit-animation: h_map 2s .6s linear alternate infinite;
	}

	.bl_map .scope:before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: #d1486c;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		opacity: .5;
		filter: alpha(opacity=50);
		-webkit-filter: alpha(opacity=50);
		animation: h_map 2s linear alternate infinite;
		-webkit-animation: h_map 2s linear alternate infinite;
	}

	@keyframes h_map {
		0% {

			transform: scale(.6);
		}

		100% {

			transform: scale(1);
		}
	}

	.bl_map .scope.s3 p {
		width: 100%;
		text-align: center;
	}

	.bl_map .scope p {
		font-size: 14.5px;
		color: #c2cff3;
		white-space: nowrap;
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -12px;
	}

	.my_timeline_prev,
	.my_timeline_next {
		float: left;
		display: inline-block;
		background-color: #fff;
		cursor: pointer;
	}

	.my_timeline_prev {
		/* width: 200px; */
		/* float: left; */
		flex: 0.5
	}

	.my_timeline_next {
		/* width: 34px;
    margin-left: -22px; */
		flex: 0.5
	}

	.init p {
		margin: 16px 0
	}

	.ul_box {
		width: 100%;

		display: inline-block;
		float: left;
		margin-top: 2px;
		overflow: hidden;
	}

	.my_timeline_item {
		display: inline-block;
		flex: 1;

	}

	.my_timeline_node {
		box-sizing: border-box;
		border-radius: 50%;
		cursor: pointer;
	}

	.my_timeline_node.active {
		background-color: #fff !important;
		color: rgb(0, 113, 203);
		font-size: 26px;
		line-height: 24px;
	}

	.my_timeline_item_line {
		/* width: 100%; */
		height: 10px;
		/* margin: -14px 0 0 28px; */
		border-top: 2px solid #E4E7ED;
		border-left: none;
	}

	.my_timeline_item_content {
		width: 80%;
		margin: auto;

	}

	h5 {
		font-family: 'Rajdhani';
		font-size: 30px;
		color: #ffffff;
		color: rgba(219, 219, 219);
		line-height: 24px;
		text-transform: uppercase;
		letter-spacing: -1px;
		margin-top: 8px;
		margin-bottom: 8px;
		width: 203px;
	}
</style>