import request from '@/request'
//获取工程项目内容 注意分类待修改
export function getproject(params){
    return request({
        url:'engineering/list',
        params
    })
}
//获取新闻内容
export function getnews(params){
    return request({
        url:'article/enterpriseNewsList',
        params
    })
}
//获取首页行业动态内容
export function hyDtai(){
    return request({
        url:'hyDtai/list'
    })
}
//获取背景图文内容
export function backgroundText(){
    return request({
        url:'backgroundText/list'
    })
} 
//首页下方轮播图  
export function smallPicture(){
    return request({
        url:'engineering/byState'
    })
} 
 

//首页荣誉图片  
export function achievementPic(){
    return request({
        url:'achievementPic/list'
    })
} 
//首页荣誉图片  
export function honor(year){
    return request({
        url:'honor/list',
        params:year
    })
} 
//首页大轮播图
export function swiperimg(){
    return request({
        url:'fengmian/list'
    })
} 
//新闻详情
export function newsdetail(params){
    return request({
        url:'article/articleDetails',
        params
    })
} 
//官网公司荣誉展示
export function honorList(params) {
    return request({
        url: '/honor/list',
        params
    })
}
//留言
export function message(params) {
    return request({
        url: '/messageBoard/create',
        method: 'post',
        data: params
    })
}
//工程详情
export function projectdetail(params){
    return request({
        url:'/engineering/details',
        params
    })
}
//企业文化
export function culture(){
    return request({
        url:'/organization/list'
    })
}
//荣誉详情
export function honordetail(params){
   return request({
    url:'/honor/byId',
    params
   })
}
//公司简介
export function compony(){
    return request({
        url:'/companyProfile/list'
    })
}
//新闻资讯
export function getmsg(params){
    return request({
        url:'/article/newsInformationList',
        params
    })
}
//新闻详情
export function msgdetail(params){
    return request({
        url:'/article/articleDetails',
        params
    })
} 
//新闻详情
export function getcruit(){
    return request({
        url:'/recruit/list'
    })
} 
//联系我们
export function concatinfo(){
    return request({
        url:'/contactus/list '
    })
} 
//联系我们
export function componyhonor(){
    return request({
        url:'/honorTitle/list '
    })
} 
//首页企业新闻
export function indexNews(params){
    return request({
        url:'/article/enterpriseNews',
        params
    })
} 
//首页企业新闻
export function indexInformation(params){
    return request({
        url:'/article/newsInformation',
        params
    })
} 
//首页新闻分类
export function NewCategary(){
    return request({
        url:'/articleType/list'
    })
} 