import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import aboutus from '../views/aboutus.vue'
import news from '@/views/news.vue'
import project  from '@/views/project.vue'
import recruit from '@/views/recruit.vue'
import contactus from '@/views/contactus.vue'
import detail from '@/views/detail.vue'
import projectdetail from '@/views/projectdetail.vue'
import honordetail from '@/views/honordetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/aboutus',
    name:'aboutus',
    component:aboutus
  },
  {
    path:'/news',
    component:news
  },
  {
    path:'/project',
    component:project
  },
  {
    path:'/recruit',
    component:recruit
  },
  {
    path:'/contactus',
    component:contactus
  },
  {
    path:'/detail',
    component:detail
  },
  {
    path:'/projectdetail',
    component:projectdetail
  },
  {
    path:'/honordetail',
    component:honordetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router
