<template>
    <div>
        <div class="page_ban">
            <div class="img compbg"></div>
            <div class="content">
                <div class="page_nav">
                    <div class="name">
                        <a style="padding: 0;" href="">联系我们</a> <a href="javascript:;">联系我们</a>
                    </div>
                
                </div>
            </div>
        </div>
        <div style="height: 80px;"></div>
        <div class="content">
            <div class="bg" :style="`background-image:url(${cartinfo.pic})`">
                    <div class="slogan wow fadeInUp animated" style="animation-delay: 0.1s; visibility: visible; animation-name: fadeInUp;">
                        <h3>{{cartinfo.title}}</h3>
                        <h5>{{cartinfo.englishNa}}</h5>
                    </div>
                    <div class="text wow fadeInUp animated" style="animation-delay: 0.3s; visibility: visible; animation-name: fadeInUp;">
                        <h3>{{cartinfo.companyName}}</h3>
                       <p>地址：{{cartinfo.site}}
</p>
<p>电话：{{cartinfo.phone}}</p>
<p>传真：{{cartinfo.phone}}</p>
<p>邮箱：{{cartinfo.email}}</p>
<p class="no_margin">网址：{{cartinfo.url}}
</p>
                    </div>
                     <div class="float">
                        <div class="introduction wow fadeInRight animated" style="animation-delay: 0.5s; visibility: visible; animation-name: fadeInRight;">
                            <h3>{{cartinfo.rightTitle}}</h3>
                            <h5>{{cartinfo.rightEngTitle}}</h5>
                            <p class="no_margin">{{cartinfo.rightIntro}}</p>
                        </div>
                        <!-- <div class="socials_wrap">
                            <a href="javascript:;" class=""><span class="social_icon social_icon_wechat">
                                    <div class="weChat_img"><img src="../images/wechat_bg.jpg"></div>
                                </span></a>
                            <a href="javascript:;" class="on"><span class="social_icon social_icon_video">
                                    <div class="weChat_img"><img src="../images/video_bg.jpg"></div>
                                </span></a>
                            <a href="javascript:;" class=""><span class="social_icon social_icon_blog">
                                    <div class="weChat_img"><img src="../images/blog_bg.jpg"></div>
                                </span></a>
                            <a href="javascript:;"><span class="social_icon social_icon_applet">
                                    <div class="weChat_img"><img src="../images/applet_bg.jpg"></div>
                                </span></a>
                        </div> -->
                       <img :src="cartinfo.weiXin" style="width: 100%;">
                    </div>
                </div>
<div style="height: 85px;"></div>
<div>
    <div style="background-color: rgb(232,232,232);padding: 8px 15px;border-radius: 14px 14px 0 0;display: flex;">
    <img src="@/assets/images/留言图标.png" style="width: 40;object-fit: contain;">
    <p style="margin-left: 20px;font-size: 20px;color:rgb(48,113,183) ;">留言联系</p>
    
    </div>
    <div style="width: 90%;margin: auto;">
    <p style="padding: 25px 0;border-bottom: 1px solid rgba(0,0,0,.2);">若您有合作意向，请您为我们留言或使用上面方式联系我们，我们将尽快给你回复，并为您提供最真诚的服务，谢谢。
</p>
<div style="height: 30px;"></div>

<el-form style="display: flex;justify-content: space-between;" :model="params" :rules="rules" ref="ruleForm">
    <div style="width: 45%;">
        <el-form-item  prop="name">
    <el-input style="margin-bottom: 20px;" v-model="params.name" placeholder="联系人"></el-input>

        </el-form-item>
  <el-form-item  prop="phone">
     <el-input style="margin-bottom: 20px;" v-model="params.phone" placeholder="电话"></el-input>
  </el-form-item>
   
    
 <el-form-item  prop="email">

    <el-input style="margin-bottom: 20px;" v-model="params.email" placeholder="邮箱"></el-input>

 </el-form-item>

    
 

</div>
<div style="width: 50%;height: 100%;">
     <el-form-item  prop="content">
    <el-input
    style="height: 100%;"
  type="textarea"
  :autosize="{ minRows: 9, maxRows: 22}"
  placeholder="留言内容"
  v-model="params.content">
</el-input>

 </el-form-item>

<div style="height: 10px;"></div>
<div style="display: flex;justify-content: end;"><el-button type="primary" @click="send('ruleForm')">发送留言</el-button></div>
</div>
</el-form>
    </div>
</div>
        </div>
<div style="height: 60px;"></div>
    </div>

</template>
<script>
import {message,concatinfo} from '@/api/index'
// import { Message } from 'element-ui';

  export default{
    data(){
        return{
            params:{
       name:'',
        phone:'',
        email:'',
        content:''
            },
            rules: {
        name: [
          { required: true, message: '联系人不能为空' },
          { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{2,20}$/, message: '联系人格式不正确' }
        ],
        phone: [
          { required: true, message: '电话不能为空' },
          { pattern: /^1\d{10}$/, message: '电话格式不正确' }
        ],
        email: [
          { required: true, message: '邮箱不能为空' },
          { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: '邮箱格式不正确' }
        ],
        content: [
          { required: true, message: '留言内容不能为空' }
        ]
      },
      cartinfo:{}
 
        }
      
    },
    mounted(){
        concatinfo().then((res)=>{
             console.log('res',res.data)
             this.cartinfo=res.data[0]
        })
    },

    methods:{
        send(params){
        console.log(params)
        this.$refs[params].validate(async (valid) => {
          if (valid) {
         const {data:res} =  await message(this.params);
         console.log(res)
      if(res.code == 200) {
        // alert('成功')
            // this.$message.success('发送留言成功！')
            this.$notify({
          title: '提示',
          message: '发送留言成功！',
        //   duration: 0,
             offset: 100,
             type: 'success'
        });
   
          }else {
          this.$notify({
          title: '提示',
          message: '发送留言失败！',
        //   duration: 0,
             offset: 100,
             type: 'error'
        });
    

          }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        //   const {data:res} = await message(this.params);
        //   console.log(res)
    
        //   if(res.code == 200) {
        //     this.$message.success('发送留言成功！')
   
        //   }else {
        //     this.$message.error('发送留言失败！')
    

        //   }
       }
    }
  }
</script>
<style scoped>
.content{
    width: 80%;
    margin:auto
}
.compbg {
    background-image: url('../assets/images/banner.png');
}

.page_ban {
    height: 404px;
    position: relative;
    text-align: center;
    z-index: 101;
}

.page_ban .img {
    width: 100%;
    height: 100%;

    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.page_ban .content {
    position: relative;
    height: 100%;
}
.content {
    width: 80%;
    margin: 0 auto;
}

.page_nav {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
div,
li {
    box-sizing: border-box;
}

.page_nav .name {
    float: left;
}
::v-deep .el-input__inner{
    background-color: rgb(240,240,240);
}
.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: white;
    font-weight: bold;
    text-decoration: none;
}

.page_nav .name a {
    padding-left: 30px;
    /* background: url() no-repeat left 30px; */
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav .list a {
    color: #e7dfd2;
    color: rgba(231, 223, 210, .7);
    margin-right: 35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
}

.page_nav .inner_bor {
    height: 4px;
    background: #f23c39;
    position: absolute;
    bottom: -4px;
    left: 0;
    display: block;
}

.bg{
    /* background-image: url('@/assets/images/cantactus.png'); */
    position: relative;
    width: 100%;
    height: 542px;
 
    background-size: cover;
    border-radius: 14px;
    -webkit-border-radius: 14px;
}
.slogan {
    position: absolute;
    top: 94px;
    left: 72px;
}
 .slogan h3 {
    font-size: 60px;
    color: #ffffff;
    line-height: 1;
    font-weight: lighter;
    letter-spacing: 42px;
    margin: initial;
}
.slogan h5 {
    font-family: 'Rajdhani';
    font-size: 36px;
    color: #ffffff;
    color: rgba(48,113,183, .5);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: -.4px;
    margin-top: 8px;
}
.text {
    position: absolute;
    bottom: 60px;
    left: 72px;
}
.text h3 {
    font-size: 24px;
    color: #3c3c48;
    margin-bottom: 14px;

}
 .text p {
    font-size: 15px;
    color: #44526a;
    line-height: 24px;
}

p {
    font-size: 14px;
    color: #95959d;
    line-height: 24px;
    text-align: justify;
}
.no_margin {
    margin-bottom: 0 !important;
}
 .float {
    position: absolute;
    height: 100%;
    right: 0px;
    background-color: rgb(242,242,242);
    padding: 42px 44px 145px 44px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.float h5{
    color: rgb(48,113,183);
}
 .float .introduction {
    margin-bottom: 10px;
}
.foot_section .links_list, .socials_wrap {
    display: inline-block;
    vertical-align: top;
}
</style>