<template>
	<div class="loading">
		<div class="page_ban">
			<div class="img compbg"></div>
			<div class="content">
				<div class="page_nav">
					<div class="name">
						<a style="padding: 0;" href="">新闻内容</a>
					</div>
					<div class="list" style="bottom:-15px">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane :label="newscatagary[0]?.name" :name="newscatagary[0]?.name"></el-tab-pane>
							<el-tab-pane :label="newscatagary[1]?.name" :name="newscatagary[1]?.name"></el-tab-pane>

						</el-tabs>

					</div>
				</div>
			</div>
		</div>
		<div style="height: 80px;"></div>
		<div style="width: 80%;margin: auto;">
			<el-row style="display: flex;flex-wrap: wrap;">

				<el-col :span="8" v-for="item in newshow" :key="item.id" class="colitem" style="cursor: pointer;">
					<div @click="changeto(item.id)">
						<el-card :body-style="{ padding: '0px' }">
							<div class="card_img1 mark" :style="{ 'background-image': 'url(' + item.pic + ')' }">
								<!-- <div class="date">{{item.releaseTime}}</div> -->
							</div>
							<div>
								<div class="news_li_con">
									<div class="title">{{item.name}}</div>
									<div class="introduction" v-html="item.content"></div>
								</div>

							</div>
						</el-card>
					</div>
				</el-col>
			</el-row>
		</div>

		<div style="width: 80%;margin: auto;">
			<div v-for="item in rest" class="newsitem"
				style="overflow: hidden; padding: 30px 0 24px;position: relative;cursor: pointer;display: flex;"
				@click="changeto(item.id)">
				<div class="date1" style=""><img :src="item.pic" style="width: 236px;height: 157px;"></img></div>
				<div class="" style="  margin-left: 35px;overflow: hidden;">
					<div style="    font-size: 18px;
    color: #000;
    font-weight: bold;
    margin-top: 15px;">
						{{item.name}}
					</div>
					<!-- <div style="font-size: 14px;
                margin-top: 7px;
    color: #ababab;">
                    {{item.releaseTime}}
                </div> -->

					<div style="font-size: 14px;
    color: #666666;
    height: 79px;
    overflow: hidden;
    -webkit-box-orient:vertical;
    margin-top: 10px;" v-html="item.content">

					</div>
				</div>
			</div>
			<div style="height: 40px;"></div>
			<div class="page_more wow fadeInUp animated" data-wow-delay=".2s" id="More"
				style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;" @click="addnews">
				<em>更多新闻</em>
			</div>
			<div style="height: 60px;"></div>
		</div>
	</div>
</template>
<script>
	import '@/assets/images/news1.png'
	import {
		getnews,
		getmsg,
		NewCategary
	} from '@/api';
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				activeName: '企业新闻',
				newshow: [],
				rest: [],
				newslist: [],
				newscatagary: [],
				page: 0,
				content: ''
			}
		},
		mounted() {

			let loadingInstance = Loading.service('loading');
			NewCategary().then((res) => {
				this.newscatagary = res.data
			})
			getnews({
				'page': 0,
				'articleType': this.activeName
			}).then((res) => {
				loadingInstance.close();
				// 遍历数组，对每个对象的richText属性进行处理
				res.data.content.forEach(item => {
					// 去除所有标签和空格
					item.content = item.content?.replace(/<[^>]+>/g, '').trim();
				});
				this.newslist = res.data.content
				let arr = this.newslist.slice(0, 3)
				this.newshow = arr
			})
		},
		methods: {

			handleClick() {
				this.page = 0;
				console.log(1111)
				this.rest = []
				let loadingInstance = Loading.service('loading');
				// if(this.activeName=='行业动态'){

				getmsg({
					'page': 0,
					'articleType': this.activeName
				}).then((res) => {

					// 遍历数组，对每个对象的richText属性进行处理
					res.data.content.forEach(item => {
						// 去除所有标签和空格
						item.content = item.content?.replace(/<[^>]+>/g, '').trim();
					});
					this.newslist = res.data.content

					let arr = this.newslist.slice(0, 3)
					this.newshow = arr
					loadingInstance.close();
				})
				//         }else if(this.activeName=='企业新闻'){
				//             getnews({'page':0,'articleType':activeName}).then((res)=>{


				//         res.data.content.forEach(item => {

				// item.content = item.content?.replace(/<[^>]+>/g, '').trim();
				// });   
				//  this.newslist=res.data.content

				//  let arr=this.newslist.slice(0,3)


				// this.newshow=arr   
				// loadingInstance.close();
				// })
				//         }
			},

			addnews() {
				this.page = this.page + 1
				// if(this.activeName=='新闻资讯'){
				getmsg({
					'page': this.page,
					'articleType': this.activeName
				}).then((res) => {
					//    console.log(res)
					for (let i = 0; i < res.data.content.length; i++) {
						res.data.content[i].content = res.data.content[i].content?.replace(/<[^>]+>/g, '').trim();

						res.data.content[i].day = res.data.content[i].releaseTime.slice(-2)
						res.data.content[i].year = res.data.content[i].releaseTime.slice(0, 7)
						this.rest.push(res.data.content[i])
					}

				})
				//         }else if(this.activeName=='企业新闻'){
				//             getnews({'page':this.page}).then((res)=>{
				//             //    console.log(res)
				//             for(let i=0;i<res.data.content.length;i++){
				//                 res.data.content[i].content= res.data.content[i].content?.replace(/<[^>]+>/g, '').trim();

				//   res.data.content[i].day=res.data.content[i].releaseTime.slice(-2)
				//   res.data.content[i].year=res.data.content[i].releaseTime.slice(0,7)
				//                 this.rest.push(res.data.content[i])
				//             }

				//         })
				//         }

			},
			changeto(id) {
				// if(this.activeName=='新闻资讯'){
				this.$router.push({
					path: '/detail?type=' + this.activeName + '&&id=' + id
				})
				//         }else if(this.activeName=='企业新闻'){
				//             this.$router.push({
				//          path:'/detail?type=2&&id='+id
				// })
				//         }

			}
		}
	}
</script>
<style scoped>
	.compbg {
		background-image: url('../assets/images/banner.png');
	}

	.page_ban {
		height: 404px;
		position: relative;
		text-align: center;
		z-index: 101;
	}

	.page_ban .img {
		width: 100%;
		height: 100%;

		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	.date {

		font-size: 26px;
		color: #dfd4c3;
		line-height: 1;
		position: absolute;
		left: 0;
		bottom: 20px;
		padding: 0 30px;
		width: 100%;
	}

	.page_ban .content {
		position: relative;
		height: 100%;
	}

	.content {
		width: 80%;
		margin: 0 auto;
	}

	.page_nav {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	div,
	li {
		box-sizing: border-box;
	}

	.page_nav .name {
		float: left;
	}

	.page_nav .list {
		position: relative;
		float: right;
		margin-right: -35px;
	}

	.page_nav a {
		float: left;
		line-height: 76px;
		position: relative;
		font-size: 14.5px;

		color: white;
		font-weight: bold;
		text-decoration: none;
	}

	.page_nav .name a {
		padding-left: 30px;
		/* background: url() no-repeat left 30px; */
	}

	.page_nav .list {
		position: relative;
		float: right;
		margin-right: -35px;
	}

	.page_nav .list a {
		color: #e7dfd2;
		color: rgba(231, 223, 210, .7);
		margin-right: 35px;
	}

	.page_nav a {
		float: left;
		line-height: 76px;
		position: relative;
		font-size: 14.5px;

		color: rgba(255, 255, 255, 0.8);
		font-weight: bold;
	}

	.page_nav .inner_bor {
		height: 4px;
		background: #f23c39;
		position: absolute;
		bottom: -4px;
		left: 0;
		display: block;
	}

	::v-deep .el-tabs__item {

		color: rgba(255, 255, 255, 0.8);
		border: none;
	}

	::v-deep .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	.card_img1 {
		border-radius: 14px;

		position: relative;
		width: 100%;
		height: 307px;

		background-size: cover;
		margin-bottom: 16px;
		border-radius: 14px;
	}

	.card_img2 {
		border-radius: 14px;
		background-image: url('../assets/images/news2.png');
		position: relative;
		width: 100%;
		height: 307px;

		background-size: cover;
		margin-bottom: 16px;
		border-radius: 14px;
	}

	.card_img3 {
		border-radius: 14px;
		background-image: url('../assets/images/news3.png');
		position: relative;
		width: 100%;
		height: 307px;

		background-size: cover;
		margin-bottom: 16px;
		border-radius: 14px;
	}

	.colitem {
		display: inline-block;
		float: none;
	}

	.mark::before {

		content: '';
		width: 100%;
		height: 100%;
		background-color: #000;
		position: absolute;
		bottom: 0;
		left: 0;
		opacity: .25;
		filter: alpha(opacity=25);
		-webkit-filter: alpha(opacity=25);
		border-radius: 14px;

	}

	::v-deep .el-card.is-always-shadow {
		border-radius: 14px;
		box-shadow: initial;
	}

	::v-deep el-card {
		border: none
	}

	.news_li_con {
		position: relative;
		padding-left: 28px;
		height: 150px;
		overflow: hidden;
	}

	.news_li_con:before {
		content: '';
		width: 6px;
		height: 21px;
		background-color: rgb(48, 113, 183);
		position: absolute;
		top: 4px;
		left: 0;
	}

	.title {
		font-size: 22px;
		color: #3c3c48;
		color: rgba(60, 60, 72, .7);
		line-height: 30px;
		font-weight: bold;
		max-width: 77%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		transition: all .4s;
		-webkit-transition: all .4s;
		-moz-transition: all .4s;
		-ms-transition: all .4s;
		-o-transition: all .4s;
	}

	.introduction {
		min-height: 72px;
		font-size: 14.5px;
		color: #848484;
		color: rgba(132, 132, 132, .7);
		line-height: 24px;
		margin: 8px 0 14px;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.more {
		position: absolute;
		bottom: 0;
		font-size: 18px;
		color: #3958cd;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: -.4px;
	}

	div {
		box-sizing: border-box;
	}

	.el-col {
		padding: 0px 8px;
	}

	.newsitem::before {
		content: '';
		width: 100%;
		height: 2px;
		background-color: #ebebeb;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.newsitem .date1 {
		width: 236px;
		float: left;
		font-family: 'Rajdhani';
		font-size: 26px;
		color: rgb(48, 113, 183);
		line-height: 1;
	}

	.title1 {
		font-size: 20px;
		color: #3c3c48;
		color: rgba(60, 60, 72, .7);
		line-height: 24px;
		font-weight: bold;
		margin-bottom: 10px;
		max-width: 77%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		transition: all .4s;
		-webkit-transition: all .4s;
		-moz-transition: all .4s;
		-ms-transition: all .4s;
		-o-transition: all .4s;
	}

	.newsitem .introduction1 {
		font-size: 14.5px;
		color: #848484;
		color: rgba(132, 132, 132, .7);
		line-height: 24px;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.page_more {
		position: relative;
		font-size: 18px;
		color: #ffffff;
		line-height: 70px;
		background-color: rgb(48, 113, 183);
		text-align: center;
		cursor: pointer;
		border-radius: 14px;
		-webkit-border-radius: 14px;
		-moz-border-radius: 14px;
		-ms-border-radius: 14px;
		-o-border-radius: 14px;
	}

	::v-deep .el-card {
		border: none;
	}
</style>