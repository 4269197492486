<template>
    <div>
        <div class="page_ban">
            <div class="img compbg"></div>
            <div class="content">
                <div class="page_nav">
                    <div class="name">
                        <a style="padding: 0;" href="/aboutus?category=honor">荣誉资讯</a> <a href="javascript:;">荣誉详情</a>
                    </div>
                    <div class="list" style="bottom:-15px">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="荣誉详情" name="first"></el-tab-pane>                
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="Wrapper news_d">
        <div class="content ql-editor" >
            <div class="name">{{ name }}</div>
            <div class="top-box"> 
                <div style="text-align: left;font-size: 14px;color: #999999;">发布时间:{{time}}</div>
                 </div>
            <div class="divider"></div>
            <div class="cont" v-html="content"></div>
           </div>
             
               
                <div class="con">
              
                </div>
            </div>
        </div>

</template>
<script>
import { honordetail } from '@/api';
export default{
    data(){
        return{
            name:'',
            content:'',
            time:''
        }
    },
    methods:{

    },
    mounted(){
        honordetail(this.$route.query).then((res)=>{
                this.content=res.data.content
                this.name=res.data.name
                this.time=res.data.createTime
        })
    },
}
</script>
<style scoped>
 .divider {
    width: 100%;
    height: 3px;
    /* background-color: #0059a7; */
    position: static;
    margin-bottom: 28px;
}
.name {
    font-size: 30px;
    color: #0007a9;
    color: rgba(0, 7, 169, .8);
    line-height: 36px;
}
.news_d {
    padding: 60px 0 90px;
    overflow: hidden;
}
.Wrapper {
    position: relative;
    background-color: #fff;
    z-index: 100;
}
/* .news_d .name {
    font-size: 30px;
    color: #0007a9;
    color: rgba(0, 7, 169, .8);
    line-height: 36px;
} */

::v-deep .el-tabs__item {

color: rgba(255, 255, 255, 0.8);
border: none;
}

::v-deep .el-tabs__nav-wrap::after {
background-color: transparent;
}
.news_d .con p {
    font-size: 14.5px;
    color: #8e8e95;
    line-height: 26px;
    margin-bottom: 26px;
    text-align: justify;
}


.compbg {
    background-image: url('../assets/images/banner.png');
}

.page_ban {
    height: 404px;
    position: relative;
    text-align: center;
    z-index: 101;
}

.page_ban .img {
    width: 100%;
    height: 100%;

    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.top-box{
    margin: 16px 0 30px;
}
.date{
  
    font-size: 26px;
    color: #dfd4c3;
    line-height: 1;
  
    bottom: 20px;
    padding: 0 30px;
    width: 100%;
}
.page_ban .content {
    position: relative;
    height: 100%;
}
.content {
    width: 80%;
    margin: 0 auto;
}

.page_nav {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
div,
li {
    box-sizing: border-box;
}

.page_nav .name {
    float: left;
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: white;
    font-weight: bold;
    text-decoration: none;
}

.page_nav .name a {
    padding-left: 30px;
    /* background: url() no-repeat left 30px; */
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav .list a {
    color: #e7dfd2;
    color: rgba(231, 223, 210, .7);
    margin-right: 35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
}
/* .cont >>> img{
    max-width: 500px;
} */
</style>