<template>
    <div>
        <div class="page_ban">
            <div class="img compbg"></div>
            <div class="content">
                <div class="page_nav">
                    <div class="name">
                        <a style="padding: 0;" href="">工程展示</a>
                         <!-- <a href="javascript:;">工程展示</a> -->
                    </div>
                
                </div>
            </div>
        </div>
        <div style="margin: 13px 0px;text-align: center;" >
    <el-radio-group v-model="radio3" style="margin: auto;" >
      <el-radio-button class="myself" style="border-radius: 26px;margin-right:18px" label="1" border @click.native="handleClick('房屋建筑')">房屋建筑</el-radio-button>
      <el-radio-button style="border-radius: 26px;margin-right:18px" label="2" border  @click.native="handleClick('市政公路')">市政公路</el-radio-button>
      <el-radio-button style="border-radius: 26px;margin-right:18px" label="3" border  @click.native="handleClick('工业建筑')">工业建筑</el-radio-button>
      <el-radio-button style="border-radius: 26px;margin-right:18px" label="4" border  @click.native="handleClick('金属门窗')">金属门窗</el-radio-button>
      <el-radio-button style="border-radius: 26px;margin-right:18px" label="5" border  @click.native="handleClick('钢结构厂')">钢结构厂</el-radio-button>
    </el-radio-group>
  </div>
  <div class="content2" style="max-width:1280px;margin: auto;display: grid;gap:20px;grid-template-columns: repeat(3,1fr);justify-content: space-between;flex-wrap: wrap;">
    <div v-for="item in  PrijectList"   style="width: 100%;border-radius: 15px 15px 0 0 ;margin-bottom: 2%;cursor: pointer;" @click="changeto(item.id)">
     
     <el-image :src="item.picture" style="width: 100%;border-radius: 15px ;"></el-image>
     <div style="padding: 2px 20px;justify-content: space-between;">
    <!-- <p>{{item.name}}</p><p class="project-font" @click="changeto(item.id)" style="cursor: pointer;" >MORE...</p> -->
    <span class="cpbt"> <b class="l">{{item.name}} </b> <i class="r">&gt;</i> </span>
   
    </div>
    </div>
    <div v-if="!PrijectList.length" style="height: 400px;display: flex;justify-content: center;align-items: center;width: 100%;margin-left: 100%;">
                 <div style="color: #999;">该工程暂无内容展示</div>
            </div>
  </div>
  <el-pagination style="text-align: center;margin: 60px;"
  background
  layout="prev, pager, next"
  prev-text="上一页"
  next-text="下一页"
   current-page.sync="0"
   @current-change="handleCurrentChange"
   :page-size="6"
  :total="total">

</el-pagination>
    </div>
    
</template>
<script>
import { getproject } from '@/api';
export default{
    data(){
        return{
            activeName:'first',
            radio3:'1',
            PrijectList:[{},{},{},{},{},{}],
            total:40,
              params: {
            type: '房屋建筑',
            page: '0',
            size: '6'
           },
      
        }
    },
    mounted(){
         getproject(this.params).then((res)=>{
            this.PrijectList=res.data.content
            this.total = res.data.totalElements
          
         })
    },
    methods:{
      changeto(id){
        this.$router.push({
             path:'/projectdetail?id='+id
    })
      },
        handleCurrentChange(val){
          console.log(val)
           this.params.page = val-1
        //    alert(this.params.page)
          getproject(this.params).then((res)=>{
            this.PrijectList=res.data.content
            this.total = res.data.totalElements
          
         })
        },
        handleClick(type){
            // console.log(type)
            this.params.type = type
            this.params.page =0
             getproject(this.params).then((res)=>{
            this.PrijectList=res.data.content
            this.total = res.data.totalElements
          
         })
            
        }
    }
}
</script>
<style scoped>
.compbg {
    background-image: url('../assets/images/banner.png');
}

.page_ban {
    height: 404px;
    position: relative;
    text-align: center;
    z-index: 101;
}

.page_ban .img {
    width: 100%;
    height: 100%;

    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.page_ban .content {
    position: relative;
    height: 100%;
}
.content {
    width: 80%;
    margin: 0 auto;
}

.page_nav {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
div,
li {
    box-sizing: border-box;
}

.page_nav .name {
    float: left;
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: white;
    font-weight: bold;
    text-decoration: none;
}

.page_nav .name a {
    padding-left: 30px;
    /* background: url() no-repeat left 30px; */
}

.page_nav .list {
    position: relative;
    float: right;
    margin-right: -35px;
}

.page_nav .list a {
    color: #e7dfd2;
    color: rgba(231, 223, 210, .7);
    margin-right: 35px;
}

.page_nav a {
    float: left;
    line-height: 76px;
    position: relative;
    font-size: 14.5px;

    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
}

.page_nav .inner_bor {
    height: 4px;
    background: #f23c39;
    position: absolute;
    bottom: -4px;
    left: 0;
    display: block;
}

::v-deep .el-tabs__item {

    color: rgba(255, 255, 255, 0.8);
    border: none;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: transparent;
}
 ::v-deep .el-radio-button__inner {
    border-radius: 30px !important;
    border-left: 1px solid #DCDFE6;
}
.project-font{
    font-family: 'Rajdhani';
    /* font-size: 18px; */
    color: #3958cd;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: -.4px;
    font-weight: bold;
}
.content2 .el-image{
    width: 389px;
    height: 258px;
}
.custom-prev,
.custom-next {
  border: 1px solid #ccc; /* 浅色边框 */
  background-color: #fff; /* 白色背景 */
  padding: 5px 10px;
  cursor: pointer;
}

    ::v-deep .el-pagination.is-background .btn-next{
      margin: 0 5px;
      background-color: white;
      color: black;
      min-width: 68px;
      height: 40px;
      border-radius: 5px;
      border:1px solid #4581ae ;
    }
    ::v-deep .el-pagination.is-background .btn-prev{
      margin: 0 5px;
      background-color: white;
      color: black;
      min-width: 68px;
      height: 40px;
      border-radius: 5px;
      border:1px solid #4581ae ;
    }
    ::v-deep .el-pager li{
        line-height: 39px;
        height: 40px;
    }
   .cpbt {
    display: block;
    height: 71px;
    line-height: 71px;

}
 b {
    font-size: 16px;
    color: #000;

}

.l {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}
b, strong {
    font-weight: 700;
}
i {
    font-size: 16px;
    color: #000;
    font-family: 宋体;
    font-style: normal;
   
}

.r {
    float: right;
}
</style>